var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4bCoyvtZGnVgkeNQGETi8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const {
  PUBLIC_SENTRY_DSN,
  PUBLIC_SENTRY_TRACE_PROPAGATION_TARGETS,
  PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
  PUBLIC_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  PUBLIC_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
  PUBLIC_SENTRY_ENVIRONMENT,
} = process.env;

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN ?? '',
  environment: PUBLIC_SENTRY_ENVIRONMENT ?? 'local',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: PUBLIC_SENTRY_TRACE_PROPAGATION_TARGETS?.split(',') ?? [],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: PUBLIC_SENTRY_TRACE_SAMPLE_RATE ?? '',
  // Session Replay
  replaysSessionSampleRate: PUBLIC_SENTRY_REPLAY_SESSION_SAMPLE_RATE ?? '',
  replaysOnErrorSampleRate: PUBLIC_SENTRY_REPLAY_ERROR_SAMPLE_RATE ?? '',
});
